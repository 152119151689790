





























import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { materialApi } from '@/services/material-service';
import { WechatImageTextService } from '@/services/wechat-imagetext-service';

@Component({
    components: {

    }
})
export default class openDialog extends Vue {
    public list: any[] = [];
    public applications: any[] = [];
    public audioDuration: number = 0;
    public fileList: any[] = [];
    public actionUrl: any = '';
    public formData: any = {
        filessrc: '',
    };
    public formLabelWidth: any = '140px';
    public rules: any = {
        channelId: [
            { required: true, message: '请选择', trigger: ['blur', 'change'] }],
            filessrc: [
            { required: true, message: '请上传', trigger: ['blur', 'change'] }]
    }
    @Inject(materialApi) private materialApi!: materialApi;
    @Inject(WechatImageTextService) private wechatImageTextService!: WechatImageTextService;
    public created(): void {
        // this.actionUrl = serverconfig.proxy["/api/application"].target + 'api/application/media/upload';
        this.actionUrl = document.location.origin + '/api/application/media/upload';
        // this.actionUrl = "http://localhost:8080/api/application/media/upload";
        this.applicationsList();
    }
    //获取专栏
    public async applicationsList(): Promise<void> {
        const res = await this.wechatImageTextService.applicationsList();
        this.applications = res;
        if (this.applications.length > 0) {//如果是添加的话，默认选中一个专栏
            this.formData.acceptChannelId = this.applications[0].id;
        }
        // this.formData.channelId =  this.applications[0].id;
    }
    public beforeAvatarUpload(files: { type: string; size: number; name?: string }) {
        // 文件类型进行判断
        console.log('files', files)
        const isLt20M = files.size / 1024 / 1024 < 20;
        // 获取时长
        console.log('files', files)
        var isFiles = this.getfileName(files.name);
        if (!isFiles) {
            this.$message.error("上传文件格式错误!");
            this.fileList = [];
            this.audioDuration = 0;
            return false;
        } else {
            if (!isLt20M) {
                this.$message.error("上传文件大小不能超过20MB!");
                this.fileList = [];
                this.audioDuration = 0;
                return false;
            } else {

            }
        }
        this.audioDuration = 0;
        return isFiles && isLt20M
    }

    public async uploadClick(formName: any): Promise<void> {
        const addRef = (this.$refs.formData as any);
        // return;
        addRef.validate(async (valid: any) => {
            if (valid) {
                var data_ = {
                    'agentId': this.formData.channelId,
                    'type': '4',
                    'url': this.formData.filessrc
                };
                // 本地文件上传
                if (this.formData.filessrc != '' && this.formData.filessrc != null && this.formData.filessrc != undefined) {
                    const res = await this.materialApi.savePic(data_);
                    this.$message({
                        message: '上传成功',
                        type: 'success'
                    });
                    this.getChild();
                } else {
                    this.$message.error("请上传文件!");
                }

            }
        })

    }
    public async handleRemove(file: any, fileList: any) {
        console.log(file, fileList);
        this.formData.filessrc = '';
    }
    public async handlePreview(file: any) {
        console.log(file);
    }
    public async successUpload(response: any, file: any, fileList: any) {
        console.log('178', response)
        if (response.body.length > 0) {
            this.formData.filessrc = response.body[0];
        } else {
            this.$message('上传失败，请重新上传！');

        }
    }
    // 去后缀名 验证是否正确
    public getfileName(name: any) {
        // var suffix = name.substring(name.lastIndexOf('.'));
        var suffix = name.substring(name.lastIndexOf('.') + 1);
        if (suffix == 'word' || suffix == 'excel' || suffix == 'ppt' || suffix == 'pdf') {
            return true
        } else {
            return false
        }

    }
    @Emit('getChild')
    public getChild(): any {
        this.formData = {
            'agentId': '',
            'url': '',
            'filessrc':'',
        }
        return true
    }
    /**
     * -END- CALL SERVICE
     */
}
